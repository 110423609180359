import axios from "axios";
import HTTP_REQUEST from "@/utils/httpRequest"
/*查询导航栏*/
export function getNavigation(params) {
  return axios({
    method: "get",
    url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/contentCategory/queryNavigation",
    params: params
  })
}

/*根据栏目id查询直接下级栏目*/
export function getDirectChildById(params) {
  return axios({
    method: "get",
    url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/contentCategory/queryDirectChildById",
    params: params
  })
}

/*根据栏目id分页查询文章列表*/
export function getPageByCategoryId(params) {
  return axios({
    method: "get",
    url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/content/pageByCategoryId",
    params: params
  })
}

/*根据文章id查询文章详情*/
export function getDetailByd(params) {
  return axios({
    method: "get",
    url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/content/queryById",
    params: params
  })
}


/*文章类服务接口-大厦服务，收费标志，管理规定*/
export function giEdificeList(params) {
  return axios({
    method: "get",
    url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/article/list",
    params: params
  })
}

// 根据图片id回显数据
export function echoImg(picId) {
  return process.env.VUE_APP_HUSSAR_DEFAULT_API + '/attachment/showPicture?image=' + `${picId}`
}

export function getMemberEquity(){
  return HTTP_REQUEST.get(process.env.VUE_APP_HUSSAR_DEFAULT_API + '/api/portal/content/queryMemberEquity')
}

/*文章下载*/
export function downLoadFile(data) {
  axios({
    method: "get",
    url: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/attachment/fileDownload',
    params: { fileId: data.fileId },
    responseType: 'blob'
  }).then(res => {
    if (res.status === 200 || res.status === 10000) {
      const blob = new Blob([res.data]);
      const elink = document.createElement('a');
      if ('download' in elink) {
        elink.download = data.fileName;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, data.fileName);
      }
    }
  }).catch(err => {
    this.$message.error('下载失败')
  })
}


/*查看活动详情*/
export function formQuery(params) {
  return axios({
    method: "get",
    url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/member/formQuery",
    params: params
  })
}
