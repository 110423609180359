import axios from 'axios'
class httpRequest{
  baseOptions(params, method = "GET") {
    let { url, data } = params;
    const BASE_URL = ''//getBaseUrl(url);
    let contentType = "application/json";
    contentType = params.contentType || contentType;
    let header1 =  {
      'content-type': contentType,
      'Client-Id': 'hussar-base'
    }
    const header = Object.assign({}, header1, params.header)
    if (params.isToken){
      //header.loginType = 'GiiaWxLogin'
      header.Authorization = uni.getStorageSync("HUSSAR_TOKEN") //'GXKSfHMx3AvpMuxG73ZtPHFIHTWLot5dMdh3ACgBUi6YkMtVYS9XJUD6kYUe'
    }
    // console.log("header",header)
    // console.log("data",data)
    let option =  {
      url: BASE_URL + url,
      method: method,
      header: header
    };
    if (null != data || undefined !== data ){
      option.data = data
    }
    return new Promise((resolve, reject) => {
      axios({
        url: option.url,
        method: option.method,
        header: option.header,
        data: option.data,
      }).then( (response) => {
          resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
  get(url, data = "") {
    let option = { url, data };
    return this.baseOptions(option);
  }

  post(url, data, isToken = false, contentType, header) {
    let params = { url, data, isToken, contentType, header };
    return this.baseOptions(params, "POST");
  }

}

export default new httpRequest()
