import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import newsList from '../views/newsList.vue'
import blueHome from '../views/blueHome.vue'

Vue.use(VueRouter)

/*const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/newsList',
    name: 'newsList',
    component: newsList
  }
]*/


const routes = [
  /*首页*/
  {
    path: '/',
    name: 'Home',
    component: Home,
    hidden: true,
    children: [
      {
        path: '/',
        name:'homeContent',
        component: () => import('@/views/homeContent.vue'),
      }
    ]
  },
  {
    path: '/',
    name: 'blueHome',
    component: blueHome,
    hidden: true,
    children: [
      {
        path: '/newsList',
        name:'newsList',
        component: () => import('@/views/newsList.vue'),
      },
      {
        path: '/giEdifice/index',
        name:'giEdifice/index',
        component: () => import('@/views/giEdifice/index.vue'),
      },
      /*专委会*/
      {
        path: '/committee/index',
        name:'committee/index',
        component: () => import('@/views/committee/index.vue'),
      },
      {
        path: '/committee/committeePreview',
        name:'committee/committeePreview',
        component: () => import('@/views/committee/committeePreview.vue'),
      },
      /*地标协会*/
      {
        path: '/giia/message',
        name:'giia/message',
        component: () => import('@/views/giia/message.vue'),
      },
      {
        path: '/giia/introduce',
        name:'giia/introduce',
        component: () => import('@/views/giia/introduce.vue'),
      },
      {
        path: '/giia/organization',
        name:'giia/organization',
        component: () => import('@/views/giia/organization.vue'),
      },
      {
        path: '/giia/business',
        name:'giia/business',
        component: () => import('@/views/giia/business.vue'),
      },
      {
        path: '/giia/member',
        name:'giia/member',
        component: () => import('@/views/giia/member/index.vue'),
      },
      {
        path: '/giia/partyBuilding',
        name:'giia/partyBuilding',
        component: () => import('@/views/giia/partyBuilding/index.vue'),
      },
      /*地标资讯*/
      {
        path: '/giInformation/wallBulletin',
        name:'giInformation/wallBulletin',
        component: () => import('@/views/giInformation/wallBulletin.vue'),
      },
      {
        path: '/giInformation/notice',
        name:'giInformation/notice',
        component: () => import('@/views/giInformation/notice.vue'),
      },
      {
        path: '/giInformation/policy',
        name:'giInformation/policy',
        component: () => import('@/views/giInformation/policy.vue'),
      },
      {
        path: '/giInformation/law',
        name:'giInformation/law',
        component: () => import('@/views/giInformation/law.vue'),
      },
      {
        path: '/richPeasant/index',
        name:'richPeasant/index',
        component: () => import('@/views/richPeasant/index.vue'),
      },
      {
        path: '/richPeasant/productInfo',
        name:'richPeasant/productInfo',
        component: () => import('@/views/richPeasant/productInfo.vue'),
      },
      {
        path: '/richPeasant/productInfoDetail',
        name:'richPeasant/productInfoDetail',
        component: () => import('@/views/richPeasant/productInfoDetail.vue'),
      },
      {
        path: '/login/register',
        name:'login/register',
        component: () => import('@/views/login/register.vue'),
      },
      {
        path: '/common/newsDetail',
        name:'common/newsDetail',
        component: () => import('@/views/common/newsDetail.vue'),
      },
      /*行业智库*/
      {
        path: '/thinkTanks/thinkTanksDetail',
        name:'thinkTanks/thinkTanksDetail',
        component: () => import('@/views/thinkTanks/thinkTanksDetail.vue'),
      },
      {
        path: '/thinkTanks/thinkTanksDetails',
        name:'thinkTanks/thinkTanksDetails',
        component: () => import('@/views/thinkTanks/thinkTanksDetails.vue'),
      },
      /*地标书画展*/
      {
        path: '/painting/paintingDetail',
        name:'painting/paintingDetail',
        component: () => import('@/views/painting/paintingDetail.vue'),
      },
      {
        path: '/details',
        name:'painting/paintingDetails',
        component: () => import('@/views/painting/paintingDetails.vue'),
      },
      /*地标产业联盟*/
      {
        path: '/alliance/allianceDetail',
        name:'alliance/allianceDetail',
        component: () => import('@/views/alliance/allianceDetail.vue'),
      },
      /*协会荣誉*/
      {
        path: '/honor/honorDetail',
        name:'honor/honorDetail',
        component: () => import('@/views/honor/honorDetail.vue'),
      },
      /*资料下载*/
      {
        path: '/onlinebusiness/datadownload/index',
        name:'onlinebusiness/datadownload/index',
        component: () => import('@/views/onlinebusiness/datadownload/index.vue'),
      },
      /*资料下载详情页*/
      {
        path: '/onlinebusiness/datadownload/infoManageDetail',
        name:'onlinebusiness/datadownload/infoManageDetail',
        component: () => import('@/views/onlinebusiness/datadownload/infoManageDetail.vue'),
      },
      /*地标查询*/
      {
        path: '/onlinebusiness/giselect/index',
        name:'onlinebusiness/giselect/index',
        component: () => import('@/views/onlinebusiness/giselect/index.vue'),
      },
      /*会员风采*/
      {
        path: '/member/memberDetail',
        name:'member/memberDetail',
        component: () => import('@/views/giia/member/memberDetail.vue'),
      },
      /*地标品牌*/
      {
        path: '/giBrand/eventDisplay/index',
        name:'giBrand/eventDisplay/index',
        component: () => import('@/views/giBrand/eventDisplay/index.vue'),
      },
      {
        path: '/giBrand/eventDisplay/eventDetail',
        name:'giBrand/eventDisplay/eventDetail',
        component: () => import('@/views/giBrand/eventDisplay/eventDetail.vue'),
      },
      {
        path: '/giBrand/onlineSelection/index',
        name:'giBrand/onlineSelection/index',
        component: () => import('@/views/giBrand/onlineSelection/index.vue'),
      },
      {
        path: '/giBrand/onlineSelection/onlineSelectionDetail',
        name:'giBrand/onlineSelection/onlineSelectionDetail',
        component: () => import('@/views/giBrand/onlineSelection/onlineSelectionDetail.vue'),
      },
      {
        path: '/giBrand/onlineSelection/onlineSelectionDetail/productDetail',
        name:'giBrand/onlineSelection/onlineSelectionDetail/productDetail',
        component: () => import('@/views/giBrand/onlineSelection/productDetail.vue'),
      },
      {
        path: '/giBrand/branding',
        name:'giBrand/branding',
        component: () => import('@/views/giBrand/branding/index.vue'),
      },
      {
        path: '/giBrand/planning',
        name:'giBrand/planning',
        component: () => import('@/views/giBrand/planning/index.vue'),
      },
      {
        path: '/giBrand/operateCase',
        name:'giBrand/operateCase',
        component: () => import('@/views/giBrand/operateCase/index.vue'),
      },
      /*保护示范区*/
      {
        path: '/giBrand/protectionZone',
        name:'giBrand/protectionZone',
        component: () => import('@/views/giBrand/protectionZone/index.vue'),
      },
      /*运用标志示范工程*/
      {
        path: '/giBrand/demonstration',
        name:'giBrand/demonstration',
        component: () => import('@/views/giBrand/demonstration/index.vue'),
      },
      /*意见反馈*/
      {
        path: '/giEdifice/feedback',
        name:'giEdifice/feedback',
        component: () => import('@/views/giEdifice/feedback.vue'),
      },
      /*大厦设施*/
      {
        path: '/giEdifice/facilities',
        name:'giEdifice/facilities',
        component: () => import('@/views/giEdifice/facilities.vue'),
      },
      /*办展流程*/
      {
        path: '/giEdifice/process',
        name:'giEdifice/process',
        component: () => import('@/views/giEdifice/process.vue'),
      },
      /*办展流程*/
      {
        path: '/giEdifice/online',
        name:'giEdifice/online',
        component: () => import('@/views/giEdifice/online.vue'),
      },
      /*展览安排*/
      {
        path: '/giEdifice/moreShow',
        name:'giEdifice/moreShow',
        component: () => import('@/views/giEdifice/moreShow.vue'),
      },
      /*展览安排详情*/
      {
        path: '/giEdifice/showDetail',
        name:'giEdifice/showDetail',
        component: () => import('@/views/giEdifice/showDetail.vue'),
      },
      /*联系我们*/
      {
        path: '/giEdifice/contactUs',
        name:'giEdifice/contactUs',
        component: () => import('@/views/giEdifice/contactUs.vue'),
      },
      /*大厦服务*/
      {
        path: '/giEdifice/buildingService',
        name:'giEdifice/buildingService',
        component: () => import('@/views/giEdifice/buildingService.vue'),
      },
      /*收费标准*/
      {
        path: '/giEdifice/chargingStandards',
        name:'giEdifice/chargingStandards',
        component: () => import('@/views/giEdifice/chargingStandards.vue'),
      },
      /*管理规定*/
      {
        path: '/giEdifice/regulations',
        name:'giEdifice/regulations',
        component: () => import('@/views/giEdifice/regulations.vue'),
      },
      /*下载中心*/
      {
        path: '/giEdifice/downloadCenter',
        name:'giEdifice/downloadCenter',
        component: () => import('@/views/giEdifice/downloadCenter.vue'),
      },
      /*下载中心详情*/
      {
        path: '/giEdifice/downloadCenterDetail',
        name:'giEdifice/downloadCenterDetail',
        component: () => import('@/views/giEdifice/downloadCenterDetail.vue'),
      },
      /*服务项目*/
      {
        path: '/giEdifice/serviceItems',
        name:'giEdifice/serviceItems',
        component: () => import('@/views/giEdifice/serviceItems.vue'),
      },
      /*地标文化*/
      {
        path: '/giCulture/yellowRiverBasin',
        name:'giCulture/yellowRiverBasin',
        component: () => import('@/views/giCulture/yellowRiverBasin.vue'),
      },
      {
        path: '/giCulture/culturalHeritage',
        name:'giCulture/culturalHeritage',
        component: () => import('@/views/giCulture/culturalHeritage/index.vue'),
      },
      {
        path: '/giCulture/giAllusion',
        name:'giCulture/giAllusion',
        component: () => import('@/views/giCulture/giAllusion/index.vue'),
      },
      /*国际交流*/
      {
        path: '/international/theBeltAndRoadInitiative',
        name:'international/theBeltAndRoadInitiative',
        component: () => import('@/views/international/theBeltAndRoadInitiative.vue'),
      },
      {
        path: '/international/internationalExchange',
        name:'international/internationalExchange',
        component: () => import('@/views/international/internationalExchange/index.vue'),
      },
      {
        path: '/international/giDevelopmentHistory',
        name:'international/giDevelopmentHistory',
        component: () => import('@/views/international/giDevelopmentHistory/index.vue'),
      },
      //地图城市信息
      {
        path: '/map/giMap',
        name:'map/giMap',
        component: () => import('@/components/giMap.vue'),
      },
      {
        path: '/map/giMapDetail',
        name:'map/giMapDetail',
        component: () => import('@/components/giMapDetail.vue'),
      },
      /*品牌馆*/
      {
        path: '/richPeasant/brandPavilion',
        name:'richPeasant/brandPavilion',
        component: () => import('@/views/richPeasant/brandPavilion.vue'),
      },
      /*文章详情*/
      {
        path: '/news/newsDetails',
        name:'news/newsDetails',
        component: () => import('@/views/news/newsDetails.vue'),
      },
      /*全局搜索*/
      {
        path: '/globalSearch',
        name:'globalSearch',
        component: () => import('@/components/globalSearch.vue'),
      },
    ]
  },
]



const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  //设置滚动条的位置
  scrollBehavior() {
    //x代表横坐标，y代表纵坐标
    return { x: 0, y: 0};
  }
})

// 解决重复点击导航时，控制台出现报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router
