// 保留两位小数
export function twoPointNumber(rule, value, callback) {
  if (!value) {
    callback();
  }
  if (!Number(value)) {
    callback(new Error('请输入数字'));
  } else {
    const re = /^\d+.?\d{0,2}$/;
    const rsCheck = re.test(value);
    if (!rsCheck) {
      callback(new Error('最多保留两位小数'));
    } else {
      callback();
    }
  }
}
// 手机号
export function phoneRules(rule, value, callback) {
  if (!value) {
    callback();
  }
  if (!Number(value)) {
    callback(new Error('请输入正确的手机号'));
  } else {
    const re = /^1([38][0-9]|4[014-9]|[59][0-35-9]|6[2567]|7[0-8])\d{8}$/;
    const rsCheck = re.test(value);
    if (!rsCheck) {
      callback(new Error('请输入正确的手机号'));
    } else {
      callback();
    }
  }
}

