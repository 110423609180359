<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
  export default {
    methods: {
    },
    //解决vue项目路由跳转页面不变问题
    mounted() {
      // 检测浏览器路由改变页面不刷新问题,hash模式的工作原理是 hashchange事件
      window.addEventListener('hashchange', () => {
        let currentPath = window.location.hash.slice(1)
        if (this.$route.path !== currentPath) {
          this.$router.push(currentPath)
        }
      }, false)
    }
  }
</script>

<style lang="less">
@import './assets/font/font.css';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center; //这个样式会影响文章详情中的文章内容的样式;先将此处注释掉，在各个页面分别设置居中
  color: #2c3e50;
  overflow-x: hidden;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

//滚动条
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  border-radius: 3px;
}
</style>
