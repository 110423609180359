import Vue from 'vue'
import Vuex from 'vuex'
import user from '@/store/modules/user';
import { setWindowParam } from 'hussar-base';
import getters from '@/store/getters';

Vue.use(Vuex)

const index = new Vuex.Store({
  modules: {
    user
  },
  getters
});
setWindowParam('hussarStore', index, {});
export default index;
