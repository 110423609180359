<!--多行文本省略-->
<template>
  <div>
    <span>
      <span class="limit-text">{{text}}</span>
      <span class="more">
        <slot name="more"></slot>
      </span>
      <slot name="after"></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'textellipsis',
  props: {
    height: Number,
    text: String,
    isLimitHeight: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
    }
  },
  watch: {
    text () {
      this.init()
    },
    isLimitHeight () {
      this.init()
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      let title = this.$el
      let textDom = this.$el.querySelector('.limit-text')
      let more = this.$el.querySelector('.more')
      more.style.display = 'none'
      this.$nextTick(() => {
        if (this.isLimitHeight) {
          if (title.scrollHeight > this.height) {
            more.style.display = 'inline-block'
            let text = this.text
            let n = 1000
            while (title.scrollHeight > this.height && n > 0) {
              if (title.scrollHeight > this.height * 3) {
                textDom.innerText = text = text.substring(0, Math.floor(text.length / 2))
              } else {
                textDom.innerText = text = text.substring(0, text.length - 1)
              }
              n--
            }
          }
        } else {
          textDom.innerText = this.text
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
