<!--内页左上角路径-->
<template>

    <div class="notice">
      <!--面包屑-->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item v-for="(item, index) in path" :key="index"
                            @click.native="toPage(item)">
          {{item.name}}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>

</template>

<script>
export default {
  name: 'topPathList',
  props: {
    // 路径对象，包含路径名称与地址
    pathObj: {
      type: Array,
      immediate: true,
      default: () => {
        return [{
          name: '首页',
          path: '/',
          params: [], // { key: '', value: '' }
        }]
      }
    }
  },
  data() {
    return {
      path: [],
    }
  },
  watch: {
    pathObj: {
      handler(n) {
        if (n && n.length > 1 && n[0].name) {
          this.path = n;
          sessionStorage.setItem('pathObj', JSON.stringify(n))
        } else {
          let arr = JSON.parse(sessionStorage.getItem('pathObj'));
          if (arr && arr.length > 1) {
            this.path = [...arr]
          } else {
            this.path = [{
              name: '首页',
              path: '/',
              params: [], // { key: '', value: '' }
            }]
          }
        }
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    toPage(val) {
      const list  = JSON.parse(sessionStorage.getItem('category'))

      // 其他跳转拼接，携带参数不固定
      /*if (val.params && val.params.length > 0) {
        let url = val.path;
        val.params.forEach(item => {
          url = url + '?' + item.key + '=' + item.value;
        })
        this.$router.push({
          path: url
        });
        return
      }*/

      //地标地图
      if (val.name == "地标地图") {
        let cityName = JSON.parse(sessionStorage.getItem('cityName'))
        let giRegionId = JSON.parse(sessionStorage.getItem('giRegionId'))
        let url = val.path
        url = url + '?cityName=' + cityName + '&giRegionId=' + giRegionId;
        this.$router.push({
          path: url
        });
        return
      }

      //地标产品
      if (val.name == "产品信息") {
        let projectId = JSON.parse(sessionStorage.getItem('projectId'))
        let url = val.path
        url = url + '?id=' + projectId;
        this.$router.push({
          path: url
        });
        return
      }

      //在线评选
      if (val.name == "评选详情") {
        let businessId = JSON.parse(sessionStorage.getItem('businessId'))
        let actiName = JSON.parse(sessionStorage.getItem('actiName'))
        let url = val.path
        url = url + '?actiId=' + businessId + '&actiName=' + actiName;
        this.$router.push({
          path: url
        });
        return
      }

      //新闻资讯详情
      if (val.name == "新闻详情") {
        let contentId = JSON.parse(sessionStorage.getItem('contentId'))
        let url = val.path
        url = url + '?contentId=' + contentId;
        this.$router.push({
          path: url
        });
        return
      }

      //全局搜索详情
      if (val.name == "文章详情") {
        let contentId = JSON.parse(sessionStorage.getItem('contentId'))
        let url = val.path
        url = url + '?contentId=' + contentId;
        this.$router.push({
          path: url
        });
        return
      }

      //地标书画展详情、会员风采、协会荣誉详情
      if (val.name == "地标书画展详情" || val.name == "会员风采详情" || val.name == "协会荣誉详情") {
        let paintId = JSON.parse(sessionStorage.getItem('paintId'))
        let typeId = JSON.parse(sessionStorage.getItem('typeId'))
        let url = val.path
        url = url + '?contentId=' + paintId + '&typeId=' + typeId;
        this.$router.push({
          path: url
        });
        return
      }

      //行业智库详情
      if (val.name == "行业智库详情") {
        let tankId = JSON.parse(sessionStorage.getItem('tankId'))
        let url = val.path
        url = url + '?tankId=' + tankId;
        this.$router.push({
          path: url
        });
        return
      }

      // 栏目id跳转拼接，携带固定参数categoryId
      if (val.path) {
        let url = val.path

        // 栏目相关路由拼接必传id
        for (let i = 0; i < list.length; i++) {
          if (val.name === list[i].categoryTitle) {
            url = url + '?categoryId=' + list[i].categoryId;
            break;
          }
        }

        this.$router.push({
          path: url
        });
      }
    },
  }
}
</script>

<style scoped lang="less">
.notice {
  float: left;
  margin: 20px 0;
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #888888;
}

::v-deep .el-breadcrumb__inner.is-link  {
  color: #888888 !important;
  font-weight: 400 !important;
}
::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner {
  color: #738198 !important;
}
::v-deep .el-breadcrumb__item {
  cursor: pointer!important;
}

</style>
