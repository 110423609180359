<template>
  <!--底部-->
  <div class="bottom-div">
    <div class="contact">
      <div class="contact-left">
        <el-row>
          <el-col :span="3">
            <div class="bottom-icon"></div>
          </el-col>
          <el-col :span="12">
            <div class="bottom-title"><span>山东省地理标志产业协会</span></div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8" :offset="3">
            <div class="phone">
              <img src="@/assets/img/telephone.png" alt="" style="margin-right: 10px; vertical-align: middle">
              <span>联系电话</span>
              <span style="margin-left: 10px;font-weight: lighter;font-size: 14px;color: #9aadd3;">0531-86098825</span>
            </div>
          </el-col>
          <el-col :span="11">
            <div class="email">
              <img src="@/assets/img/email.png" alt="" style="margin-right: 10px; vertical-align: middle">
              <span>公司邮箱</span>
              <span style="margin-left: 10px;font-weight: lighter;font-size: 14px;color: #9aadd3;">sddbxh88089700@163.com</span>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8" :offset="3">
            <div class="info">
              <img src="@/assets/img/filings.png" alt="" style="margin-right: 10px; vertical-align: middle">
              <span>备案信息</span>
              <span style="margin-left: 10px;font-weight: lighter;font-size: 14px;color: #9aadd3;"><a target="_blank" href="https://beian.miit.gov.cn/" style="color: #9aadd3;text-decoration: none">备案号:鲁ICP备2023021469号</a></span>
            </div>
          </el-col>
          <el-col :span="13">
            <div class="location">
              <img src="@/assets/img/address.png" alt="" style="margin-right: 10px; vertical-align: middle">
              <span>公司地址</span>
              <span style="margin-left: 10px;font-weight: lighter;font-size: 14px;color: #9aadd3;">山东省济南市槐荫区济南西站东广场南综合体东樽(地理标志产业大厦）</span>
            </div>
          </el-col>
        </el-row>
        <!--<div class="bottom-first">
          <div class="bottom-icon"></div>
          <div class="bottom-title"><span>山东省地理标志产业协会</span></div>
        </div>
        <div class="bottom-second">
          <div class="phone">
            <img src="@/assets/img/telephone.png" alt="" style="margin-right: 10px; vertical-align: middle">
            <span>联系电话</span>
            <span style="margin-left: 30px;font-weight: lighter;font-size: 14px;color: #9aadd3;">0531-86098825</span>
          </div>
          <div class="email">
            <img src="@/assets/img/email.png" alt="" style="margin-right: 10px; vertical-align: middle">
            <span>公司邮箱</span>
            <span style="margin-left: 30px;font-weight: lighter;font-size: 14px;color: #9aadd3;">sddbxh88089700@163.com</span>
          </div>
        </div>
        <div class="bottom-third">
          <div class="info">
            <img src="@/assets/img/filings.png" alt="" style="margin-right: 10px; vertical-align: middle">
            <span>备案信息</span>
            <span style="margin-left: 30px;font-weight: lighter;font-size: 14px;color: #9aadd3;"><a target="_blank" href="https://beian.miit.gov.cn/" style="color: #9aadd3;text-decoration: none">备案号:鲁ICP备2023021469号</a></span>
          </div>
          <div class="location">
            <img src="@/assets/img/address.png" alt="" style="margin-right: 10px; vertical-align: middle">
            <span>公司地址</span>
            <span style="margin-left: 30px;font-weight: lighter;font-size: 14px;color: #9aadd3;">山东省济南市槐荫区恒大财富中心2号楼25层</span>
          </div>
        </div>-->
      </div>
      <div class="contact-right">
        <div class="code-left">
          <img src="@/assets/img/transaction.jpg" alt="" style="width: 100px; height: 100px; margin-top: 2px">
          <div class="transaction"><span>地理标志产品交易中心</span></div>
        </div>
        <div class="code-right">
          <img src="@/assets/img/code.jpg" alt="" style="width: 105px; height: 105px">
          <div class="follow"><span>关注协会公众号</span></div>
        </div>
      </div>
    </div>
    <div class="line-class"></div>
    <div class="address">
      <span>主办单位：山东省地理标志产业协会 | 技术支持：地标（山东）智能科技有限公司</span>
    </div>

  </div>
</template>

<script>
    export default {
        name: "pageFooter"
    }
</script>

<style lang="less" scoped>
  .bottom-div {
    text-align: center;
    overflow-x: hidden;//会出现横向滚动条，未找到原因，暂时隐藏
    width: 100%;
    height: 238px;
    background-color: #004299;
    .contact {
      width: 90%;
      height: 188px;
      color: #ffffff;
      overflow: hidden; //解决子div设置margin-top使父div也跟着向下移动的问题
      display: flex;
      justify-content: center;
      .contact-left {
        width: 70%;
        .bottom-icon {
          float: right;
          background: url(../assets/img/bottomIcon.jpg);
          width: 63px;
          height: 63px;
          margin-left: 27%;
          margin-top: 30px;
        }
        .bottom-title {
          float: left;
          margin-left: 5px;
          margin-top: 50px;
          font-size: 20px;
          font-weight: 600;
          letter-spacing: 2px;
        }
        .phone {
          float: left;
        }
        .email {
          float: left;
        }
        .info {
          float: left;
        }
        .location {
          float: left;
        }
      }
      .contact-right {
        display: flex;
        //width: 30%;
        margin-top: 20px;
        .code-left {
          .transaction {
            font-size: 14px;
            color: #cadff4;
            margin-top: 3px;
          }
        }
        .code-right {
          margin-left: 20px;
          .follow {
            font-size: 14px;
            color: #cadff4;
          }
        }
      }
    }
    .line-class {
      border: 1px solid #3F66B0;
      width: 100%;
    }
    .address {
      margin: 12px auto;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #A9BCDD;
      line-height: 21px;
    }
  }
  ::v-deep .el-row {
    margin: 10px 0;
  }

  @media screen and (max-width: 1366px) {
    .bottom-div {
      text-align: center;
      overflow-x: hidden;//会出现横向滚动条，未找到原因，暂时隐藏
      width: 100%;
      height: 238px;
      background-color: #004299;
      .contact {
        width: 90%;
        height: 188px;
        color: #ffffff;
        overflow: hidden; //解决子div设置margin-top使父div也跟着向下移动的问题
        display: flex;
        justify-content: center;
        .contact-left {
          width: 95%;
          .bottom-icon {
            float: right;
            background: url(../assets/img/bottomIcon.jpg);
            width: 63px;
            height: 63px;
            margin-left: 27%;
            margin-top: 30px;
          }
          .bottom-title {
            float: left;
            margin-left: 5px;
            margin-top: 50px;
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 2px;
          }
          .phone {
            float: left;
          }
          .email {
            float: left;
          }
          .info {
            float: left;
          }
          .location {
            float: left;
          }
        }
        .contact-right {
          display: flex;
          //width: 30%;
          margin-top: 20px;
          .code-left {
            .transaction {
              font-size: 14px;
              color: #cadff4;
              margin-top: 3px;
            }
          }
          .code-right {
            margin-left: 20px;
            .follow {
              font-size: 14px;
              color: #cadff4;
            }
          }
        }
      }
      .line-class {
        border: 1px solid #3F66B0;
        width: 100%;
      }
      .address {
        margin: 12px auto;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #A9BCDD;
        line-height: 21px;
      }
    }
    ::v-deep .el-row {
      margin: 10px 0;
    }
  }

</style>
