<!--在线客服-->
<template>
  <div class="right-button-div">
    <div class="free-experience-div" @click="openDialog">
      <div><img src="@/assets/img/free-experience.png" alt="" width="26"></div>
      <div class="button-text">在线客服</div>
    </div>
    <el-divider></el-divider>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "customerService",
  data() {
      return {

      }
  },
  methods: {
    getLink() {
      axios({
        method: "get",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/custom/onlineService/onlineService/getLink",
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.linkUrl = res.data.data
          window.open(this.linkUrl, '_blank')
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    openDialog() {
      this.getLink()
    }
  },
  mounted() {
    //this.getLink()
  }
}
</script>

<style lang="less" scoped>
  .right-button-div {
    text-align: center;
    z-index: 12;
    width: 80px;
    height: 90px;
    background: #196CF6;
    border-radius: 4px;
    position: fixed;
    bottom: 195px;
    right: 19px;
    padding-top: 13px;
    box-sizing: border-box;

    .free-experience-div {
      cursor: pointer;
    }

    .button-text {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular;
      line-height: 14px;
      color: #FFFFFF;
      margin-top: 5px;
    }

    .el-divider--horizontal {
      margin: 11px 12px;
      width: 56px;
    }
  }
  @media screen and (max-width: 1366px) {
    .right-button-div {
      text-align: center;
      z-index: 12;
      width: 81px;
      height: 90px;
      background: #196CF6;
      border-radius: 4px;
      position: fixed;
      bottom: 339px;
      right: 19px;
      padding-top: 13px;
      box-sizing: border-box;

      .free-experience-div {
        cursor: pointer;
      }

      .button-text {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular;
        line-height: 14px;
        color: #FFFFFF;
        margin-top: 5px;
      }

      .el-divider--horizontal {
        margin: 11px 12px;
        width: 56px;
      }
    }
  }
</style>
