<template>
  <div>
    <!--导航标题-->
    <div :class="scrollActive == 0 ? 'banner-title' : 'scroll-title'">
      <div style="width: 80px">
        <div class="emblem1">
        </div>
      </div>
      <div style="width: 80px">
        <div class="emblem2">
        </div>
      </div>
          <div class="mainTitle">
            <span>地理标志展示推广中心</span>
          </div>
          <!--导航部分-->
          <div class="nav">
            <div class="nav-item" @click="indexClick">首页</div>
            <div v-for="(item, index) in indexData" :key="index">
              <div class="nav-item">
                <el-dropdown placement="bottom-start" trigger="hover" @click.native="toPage(item)">
                <span class="el-dropdown-link link_icon switchColor">
                  {{item.categoryTitle}}
                </span>
                  <el-dropdown-menu slot="dropdown" v-if="item.childList.length != 0">
                    <el-dropdown-item v-for="(son, indexSon) in item.childList" :key="indexSon" @click.native="toPage(son)">
                      <span class="dropdownSpan">{{son.categoryTitle}}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </div>
          <!--搜索按钮-->
          <div class="search" @click="globalSearchClick">
          </div>
          <!--注册按钮-->
          <div class="register">
            <button class="registerBtn" @click="jumpPage('/login/register')" v-if="isLoginValue == false">注册/登录</button>
            <el-dropdown v-if="isLoginValue == true">
              <el-button type="primary">
                {{userName}}
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="noLogin()">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
      </div>
    <!--弹框-->
    <login-dialog :dialogVisible.sync="dialogVisible" @isLogin="isLogin"></login-dialog>
  </div>
</template>

<script>
import loginDialog from '@/components/indexComponents/loginDialog.vue'
import {getNavigation} from '@/utils/commonApi.js'
import axios from 'axios'
export default {
  name: "pageTop",
  components: { loginDialog },
  data() {
    return {
      dialogVisible: false,
      scrollActive: 0,
      indexData: [],
      isLoginValue: '', //接收登录组件传值
      userName: '', //用户名
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll); //添加监听事件
    // 页面即将刷新或者关闭
    window.onbeforeunload = function() {
      sessionStorage.removeItem("topMenu");//清除缓存
    }
    // sessionStorage中是否已存在数据，若没有，则调用，避免重复调用
    if (!JSON.parse(sessionStorage.getItem('topMenu'))) {
      this.queryNavigation()
    } else {
      this.indexData = JSON.parse(sessionStorage.getItem('topMenu')) || []
    }

    //判断session中是否有用户信息
    if (sessionStorage.getItem('userName') && sessionStorage.getItem('userName') != '') {
      this.isLoginValue = true
      //从session中获取userName
      let telphone = JSON.parse(sessionStorage.getItem("userName"));
      //手机号中间显示****
      this.userName = telphone.substr(0,3) + '****' + telphone.substr(7);
    }
  },
  methods: {
    //点击搜索按钮
    globalSearchClick() {
      this.$router.push({
        path: '/globalSearch',
      });
    },

    isLogin(item) {
      this.isLoginValue = item //隐藏注册/登录按钮
      //从session中获取userName
      let telphone = JSON.parse(sessionStorage.getItem("userName"));
      //手机号中间显示****
      this.userName = telphone.substr(0,3) + '****' + telphone.substr(7);
    },
    //退出登录
    noLogin() {
      this.$confirm('确认退出？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showClose: false,
        type: 'warning'
      }).then(() => {
        //按钮切换
        this.isLoginValue = false
        //清空session中的token和userName
        sessionStorage.setItem('HussarToken', '');
        sessionStorage.setItem('userName', '');
      })
    },
      //调用接口，获取栏目导航
    queryNavigation() {
      this.indexData = []
      getNavigation().then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          let data = res.data.data
          this.indexData = data
          sessionStorage.setItem('topMenu', JSON.stringify(data));
          this.handleTreeData()
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    // 树数据扁平化
    handleTreeData() {
      const data = JSON.parse(JSON.stringify(this.indexData));

      //递归
      function flat(arr){
        let newArr=[];
        for(let i=0; i < arr.length ; i++){
          if(arr[i].childList){
            newArr.push(...flat(arr[i].childList));
            delete arr[i].childList;
          }
          newArr.push({...arr[i]});
        }
        return newArr;
      }

      let flatArr= flat(data);
      sessionStorage.setItem('category', JSON.stringify(flatArr)); // 用于内页面面包屑的跳转传参
    },

    jumpPage() {
      this.dialogVisible = true
      sessionStorage.setItem('title', ''); //将session中的title置空，与在线业务部分的登录注册区分
    },

    toPage(item) {
      let path = item.categoryPath
      if (path.indexOf('http') != -1) {
        window.open(path, '_blank')
      } else {
        if (item.categoryPath != "") {
          this.$router.push({
            path: item.categoryPath,
            query: {
              categoryId:item.categoryId,
            }
          });
        }
      }
    },

    indexClick() {
      this.$router.push({
        path: '/'
      });
    },

    //监控scrollTop 的值，根据其变化控制顶部样式
    handleScroll() {
      if (document.documentElement.scrollTop > 0) { //鼠标向下滚动显示蓝色
        this.scrollActive = 1
      } else { //在顶部显示透明色
        this.scrollActive = 0
      }
    },

  }
}
</script>

<style lang="less" scoped>
  .banner-title {
    background-color: transparent;
    height: 80px;
    width: 100%;
    position:fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    z-index: 999;

    .emblem1 {
      background: url(../assets/img/logo1.png);
      height: 46px;
      width: 45px;
      margin-top: 17px;
      margin-left: 26px;
    }
    .emblem2 {
      background: url(../assets/img/logo2.png);
      height: 45px;
      width: 45px;
      margin-top: 17px;
      margin-left: 8px;
    }

    .mainTitle {
      white-space: nowrap;//解决浏览器缩小页面文字换行问题
      max-width: 240px;
      height: 24px;
      font-size: 24px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 36px;
      margin-top: 24px;
      margin-left: 8px;
    }

    .nav {
      display: flex;
      color: #ffffff;
      margin-left: 50px;
      min-width: 840px;

      .nav-item {
        margin: 32px 16px;
        cursor: pointer;
        width: 60px;
        font-size: 15px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 23px;

        .switchColor {
          font-size: 15px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 23px;
        }
      }
    }


    .search {
      margin-top: 34px;
      margin-left: 12px;
      height: 22px;
      width: 22px;
      background: url(../assets/img/search1.png);
    }
    .search:hover {
      cursor: pointer;
    }

    .register{
      margin: 21px;

      .registerBtn {
        width: 80px;
        height: 38px;
        background: linear-gradient(180deg, #FFC43C 0%, #F49D3D 100%);
        border-radius: 5px;
        font-size: 15px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 23px;
        border: none;
      }
      .registerBtn:hover {
        cursor:pointer;
      }
    }
  }

  .scroll-title {
    background-color: rgba(14, 83, 188, 1);
    height: 80px;
    width: 100%;
    position:fixed;
    justify-content: center;
    top: 0;
    left: 0;
    display: flex;
    z-index: 999;

    .emblem1 {
      background: url(../assets/img/logo1.png);
      height: 46px;
      width: 45px;
      margin-top: 17px;
      margin-left: 26px;
    }
    .emblem2 {
      background: url(../assets/img/logo2.png);
      height: 45px;
      width: 45px;
      margin-top: 17px;
      margin-left: 8px;
    }

    .mainTitle {
      white-space: nowrap;//解决浏览器缩小页面文字换行问题
      max-width: 240px;
      height: 24px;
      font-size: 24px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 36px;
      margin-top: 24px;
      margin-left: 8px;
    }

    .nav {
      display: flex;
      color: #ffffff;
      margin-left: 50px;
      min-width: 840px;

      .nav-item {
        margin: 32px 16px;
        cursor: pointer;
        width: 60px;
        font-size: 15px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 23px;

        .switchColor {
          font-size: 15px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 23px;
        }
      }
    }


    .search {
      margin-top: 34px;
      margin-left: 12px;
      height: 22px;
      width: 22px;
      background: url(../assets/img/search1.png);
    }

    .register{
      margin: 21px;

      .registerBtn {
        width: 80px;
        height: 38px;
        background: linear-gradient(180deg, #FFC43C 0%, #F49D3D 100%);
        border-radius: 5px;
        font-size: 15px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 23px;
        border: none;
      }
      .registerBtn:hover {
        cursor:pointer;
      }
    }
  }

  .el-button--primary {
    width: 100px;
    height: 38px;
    background: linear-gradient(180deg, #FFC43C 0%, #F49D3D 100%);
    border-radius: 5px;
    font-size: 15px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 16px;
    border: none;
    padding: 7px;
  }
  .el-button--primary:hover {
    background: linear-gradient(180deg, #FFC43C 0%, #F49D3D 100%);
  }
  .el-button--primary:focus {
    background: linear-gradient(180deg, #FFC43C 0%, #F49D3D 100%);
  }
  .el-button--primary:active {
    background: linear-gradient(180deg, #FFC43C 0%, #F49D3D 100%);
  }

  @media screen and (max-width: 1366px){
    .banner-title {
      padding-left: 3px;
      background-color: transparent;
      height: 80px;
      width: 100%;
      position:fixed;
      top: 0;
      left: 0;
      display: flex;
      z-index: 999;

      .emblem1 {
        background: url(../assets/img/logo1.png);
        height: 46px;
        width: 45px;
        margin-top: 17px;
        margin-left: 26px;
      }
      .emblem2 {
        background: url(../assets/img/logo2.png);
        height: 45px;
        width: 45px;
        margin-top: 17px;
        margin-left: 8px;
      }

      .mainTitle {
        white-space: nowrap;//解决浏览器缩小页面文字换行问题
        max-width: 240px;
        height: 24px;
        font-size: 24px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 36px;
        margin-top: 24px;
        margin-left: 8px;
      }

      .nav {
        display: flex;
        color: #ffffff;
        margin-left: 50px;
        min-width: 450px;

        .nav-item {
          margin: 32px 8px;
          cursor: pointer;
          width: 60px;
          font-size: 15px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 23px;

          .switchColor {
            font-size: 15px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 23px;
          }
        }
      }


      .search {
        margin-top: 34px;
        margin-left: 12px;
        height: 22px;
        width: 22px;
        background: url(../assets/img/search1.png);
      }

      .register{
        margin: 21px;

        .registerBtn {
          width: 80px;
          height: 38px;
          background: linear-gradient(180deg, #FFC43C 0%, #F49D3D 100%);
          border-radius: 5px;
          font-size: 15px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 23px;
          border: none;
        }
        .registerBtn:hover {
          cursor:pointer;
        }
      }
    }

    .scroll-title {
      padding-left: 3px;
      background-color: rgba(14, 83, 188, 1);
      height: 80px;
      width: 100%;
      position:fixed;
      top: 0;
      left: 0;
      display: flex;
      z-index: 999;

      .emblem1 {
        background: url(../assets/img/logo1.png);
        height: 46px;
        width: 45px;
        margin-top: 17px;
        margin-left: 26px;
      }
      .emblem2 {
        background: url(../assets/img/logo2.png);
        height: 45px;
        width: 45px;
        margin-top: 17px;
        margin-left: 8px;
      }

      .mainTitle {
        white-space: nowrap;//解决浏览器缩小页面文字换行问题
        max-width: 240px;
        height: 24px;
        font-size: 24px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 36px;
        margin-top: 24px;
        margin-left: 8px;
      }

      .nav {
        display: flex;
        color: #ffffff;
        margin-left: 50px;
        min-width: 450px;

        .nav-item {
          margin: 32px 8px;
          cursor: pointer;
          width: 60px;
          font-size: 15px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 23px;

          .switchColor {
            font-size: 15px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 23px;
          }
        }
      }


      .search {
        margin-top: 34px;
        margin-left: 12px;
        height: 22px;
        width: 22px;
        background: url(../assets/img/search1.png);
      }

      .register{
        margin: 21px;

        .registerBtn {
          width: 80px;
          height: 38px;
          background: linear-gradient(180deg, #FFC43C 0%, #F49D3D 100%);
          border-radius: 5px;
          font-size: 15px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 23px;
          border: none;
        }
        .registerBtn:hover {
          cursor:pointer;
        }
      }
    }
    .el-button--primary {
      width: 100px;
      height: 38px;
      background: linear-gradient(180deg, #FFC43C 0%, #F49D3D 100%);
      border-radius: 5px;
      font-size: 15px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 16px;
      border: none;
      padding: 7px;
    }
    .el-button--primary:hover {
      background: linear-gradient(180deg, #FFC43C 0%, #F49D3D 100%);
    }
    .el-button--primary:focus {
      background: linear-gradient(180deg, #FFC43C 0%, #F49D3D 100%);
    }
    .el-button--primary:active {
      background: linear-gradient(180deg, #FFC43C 0%, #F49D3D 100%);
    }
  }

</style>
