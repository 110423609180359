<!--登录注册组件-->
<template>
  <div>
    <el-dialog
      :visible.sync="visible"
      @close="closeFn"
      width="780px"
      :append-to-body='true'>
      <div class="dialogClass">
        <!--左侧-->
        <div class="dialogLeft">
          <div class="bottomImg">
            <img src="@/assets/img/login/loginLeftBg.png" alt="">
          </div>
          <div class="topImg">
            <img src="@/assets/img/login/giIcon1.png" alt="">
            <img src="@/assets/img/login/giIcon2.png" alt="" style="margin-left: 32px">
          </div>
          <div class="leftTitle">
            地理标志展示推广中心
          </div>
        </div>
        <!--右侧-->
        <div class="dialogRight">
          <div class="tabClass">
            <div v-for="(item, index) in menuList" :key="index" @click="clickMenu(item, index)"
                 :class="menuIndex === index ? 'active' : 'unActive'" >
              <div class="fontClass">{{item.name}}</div>
            </div>
          </div>
          <div class="loginDiv" v-show="menuIndex == 0">
            <el-row>
              <el-col :span="20" :offset="2">
                <div class="welcome">欢迎登录</div>
                <el-form
                  :model="loginForm"
                  :rules="loginRules"
                  ref="loginForm"
                >
                  <el-form-item label="手机号" prop="userName">
                    <el-input v-model="loginForm.userName" placeholder="请输入" clearable></el-input>
                  </el-form-item>
                  <!--增大间距，占位使用-->
                  <el-form-item></el-form-item>
                  <el-form-item label="密码" prop="userPassword">
                    <el-input v-model="loginForm.userPassword" show-password placeholder="请输入" clearable></el-input>
                  </el-form-item>
                  <!--增大间距，占位使用-->
                  <el-form-item></el-form-item>
                  <el-form-item></el-form-item>
                  <el-form-item></el-form-item>
                  <el-form-item>
                    <!--登录按钮-->
                    <el-button class="moreBtn" @click="handleLogin()">登录</el-button>
                  </el-form-item>
                  <el-form-item>
                    <div class="noAuthentication" @click="registerClick()">
                      立即注册
                    </div>
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>
          </div>
          <div class="register" v-show="menuIndex == 1">
            <!--基本信息，企业认证-->
            <el-row style="margin-bottom: 20px">
              <el-col :span="10" :offset="2">
                <div :class="activeIndex === 0 ? 'activeReg' : 'unActiveReg'">
                  <span class="tabSpan">① 基本信息</span>
                </div>
              </el-col>
              <el-col :span="10" :offset="2">
                <div :class="activeIndex === 1 ? 'activeReg' : 'unActiveReg'">
                  <span class="tabSpan">② 企业认证</span>
                </div>
              </el-col>
            </el-row>
            <el-row v-show="activeIndex === 0">
              <el-col :span="20" :offset="2">
                <el-form ref="firstForm" :model="firstForm" :rules="firstRules">
                  <el-form-item label="用户名" prop="societyName">
                    <el-input v-model="firstForm.societyName" placeholder="请输入" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="手机号" prop="userAccount">
                    <div class="phoneClass">
                      <el-input v-model="firstForm.userAccount" placeholder="请输入" clearable style="width: 275px"></el-input>
                      <el-button :disabled="disabled" class="codeBtn" @click="getCode">{{codeTitle}}</el-button>
                    </div>
                  </el-form-item>
                  <el-form-item label="验证码" prop="code">
                    <el-input v-model="firstForm.code" placeholder="请输入" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="密码" prop="userPassword">
                    <el-input v-model="firstForm.userPassword" show-password autocomplete="new-password"
                              placeholder="请输入" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="再次输入密码" prop="twoPwd">
                    <el-input v-model="firstForm.twoPwd" show-password placeholder="请输入" clearable></el-input>
                  </el-form-item>
                  <!--<el-form-item label="邮箱" prop="email">
                    <el-input v-model="firstForm.email" placeholder="请输入" clearable></el-input>
                  </el-form-item>-->
                </el-form>
              </el-col>
            </el-row>
            <el-row v-show="activeIndex === 1">
              <el-col :span="20" :offset="2">
                <div class="enterpriseDiv">
                  <el-form ref="secondForm" :model="secondForm" :rules="secondRules">
                    <el-form-item label="单位名称" prop="entityName">
                      <el-input v-model="secondForm.entityName" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="社会统一信用代码" prop="entityCode">
                      <el-input v-model="secondForm.entityCode" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="地址" prop="entityAddr">
                      <el-input v-model="secondForm.entityAddr" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="法定代表人" prop="entityLegal">
                      <el-input v-model="secondForm.entityLegal" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话" prop="entityTel">
                      <el-input v-model="secondForm.entityTel" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="经营范围" prop="businessScope">
                      <el-input v-model="secondForm.businessScope" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="营业执照" prop="orgImg" class="imgCardClass">
                      <el-upload accept="image/jpeg,image/jpg,image/png"
                                 :show-file-list="false"
                                 :http-request="uploadMethod"
                                 :action="uploadApi"
                                 :headers="headerObj"
                                 list-type="picture-card"
                                 :on-success="uploadSuccess"
                                 v-if="!imgUpload_112AuthSrcData"
                      >
                        <i slot="default" class="el-icon-plus"></i>
                        <div slot="file" slot-scope="{file}">

                          <span class="el-upload-list__item-actions"></span>
                        </div>
                      </el-upload>
                      <img
                        class="el-upload-list__item-thumbnail"
                        :src="imgUpload_112AuthSrcData" alt=""
                        v-else
                        style="width: 100px; height: 100px"
                      >
                    </el-form-item>
                  </el-form>
                </div>
              </el-col>
            </el-row>
            <el-row class="regBtn">
              <el-col :span="20" :offset="2">
                <!--<el-button class="moreBtn" @click="clickTab(0)" v-if="activeIndex === 1">上一步</el-button>-->
                <div v-if="activeIndex === 1" class="noAuthentication" @click="clickEnd">暂不认证</div>
                <el-button class="moreBtn" @click="clickTab(1)" v-if="activeIndex === 0">注册</el-button>
                <div v-if="activeIndex === 0" class="toLoginClass">已有账号，<span class="loginNowClass" @click="loginNow">立即登录</span></div>
                <el-button class="moreBtn" @click="saveData" v-if="activeIndex === 1">注册完成</el-button>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { HussarPasswordEncryption } from 'hussar-base';
import eventBus from "@/utils/eventBus.js";
import axios from 'axios'
import { phoneRules } from '@/utils/validataRules'
export default {
  name: "loginDialog",
  props: {
    dialogVisible: false,
  },
  watch: {
    dialogVisible: function (nelVal, oldVal) {
      this.visible = nelVal
    }
  },
  data() {
    var confirmPassword = (rule, value, callback) => {
      if (value !== this.firstForm.userPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      menuList: [
        {
          name:"登录"
        },
        {
          name:"注册"
        }
      ],
      menuIndex: 0,

      loginForm: {
        userName: '',
        userPassword: '',
        code: '',
      },
      loginRules: {
        userName: [{ required: true, message: '请输入', trigger: 'blur' }],
        userPassword: [{ required: true, message: '请输入', trigger: 'blur' }],
        code: [{ required: true, message: '请输入', trigger: 'blur' }],
      },

      //注册部分
      activeIndex: 0,
      firstForm: {
        societyName: '',
        userAccount: '',
        code: '',
        userPassword: '',
        twoPwd: '',
        //email: '',
      },
      secondForm: {
        entityName: '',
        entityCode: '',
        entityAddr: '',
        entityLegal: '',
        entityTel: '',
        businessScope: '',
        orgImg: ''
      },
      firstRules: {
        societyName: [{ required: true, message: '请输入', trigger: 'blur' }],
        userAccount: [{ required: true, message: '请输入', trigger: 'blur' },
          { validator: phoneRules, trigger: 'blur' }],
        code: [{ required: true, message: '请输入', trigger: 'blur' }],
        userPassword: [{ required: true, message: '请输入', trigger: 'blur' }],
        twoPwd: [{ required: true, message: '请输入', trigger: 'blur' },
          { validator: confirmPassword, trigger: 'blur' }],
      },
      secondRules: {
        entityName: [{ required: true, message: '请输入', trigger: 'blur' }],
        entityCode: [{ required: true, message: '请输入', trigger: 'blur' }],
        entityAddr: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
      uploadApi: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/attachment/uploadfilewithdrag',
      disabled: false, //是否禁用按钮
      second: '', //倒计时
      timer: null, //计时器
      headerObj: {
        Authorization: JSON.parse(sessionStorage.getItem("HussarToken")) //请求头，携带token
      },
      userId: '',
      imgUpload_112AuthSrcData: '',

    }
  },
  //计算属性
  computed: {
    codeTitle() {
      return this.disabled ? `${this.second}秒后重发` : '获取验证码';
    },
  },
  mounted(){
    this.getBaseData()
    eventBus.$off('toLogin')
    eventBus.$on('toLogin',( title)=>{
      this.visible = true
    })
  },
  methods: {
    closeFn() {
      this.menuIndex = 0
      this.$emit('update:dialogVisible', false)
      this.form = {}
      this.$refs['loginForm'].clearValidate()
      this.$refs['firstForm'].clearValidate()
      this.$refs['secondForm'].clearValidate()
    },

    //登录注册点击切换
    clickMenu(menu, index) {
      this.menuIndex = index;
      this.$emit('menuActive', { menu, index })
      this.activeIndex = 0
    },

    //注册部分的方法
    clickTab(index) {
      if (index === 1) {
        this.$refs['firstForm'].validate((valid) => {
          if (valid) {
            //点击下一步调用注册接口
            let params = {
              userAccount: this.firstForm.userAccount, //手机号
              userPassword: this.firstForm.userPassword, //密码
              smsCode: this.firstForm.code, //验证码
              societyName: this.firstForm.societyName, //用户名
            }
            axios({
              method: "post",
              url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/user/registration",
              data: params,
            }).then(res => {
              if (res.data.code === 10000 || res.data.code === 200) {
                this.userId = res.data.data.userId
                //调用登录接口
                const passwordEncryption = new HussarPasswordEncryption();
                const userInfo = {
                  username: this.firstForm.userAccount,
                  password: passwordEncryption.encrypt(this.firstForm.userPassword), // pwd
                  //password: this.firstForm.userPassword, // pwd
                  //code: this.firstForm.code,
                  randomStr: 'blockPuzzle',
                  kaptcha: this.firstForm.kaptcha,
                  totp: this.firstForm.totp,
                  kaptchasuffix: this.firstForm.kaptchasuffix,
                  isIndex: 'index'
                };
                this.$store.dispatch('Login', { userInfo }).then(() => {
                  console.log("登录成功")
                  this.activeIndex = index;

                  let tokenDataTemp = sessionStorage.getItem("HussarToken")
                  let tokenData = JSON.parse(tokenDataTemp)
                  //调用获取用户信息接口，需要拿到sysUserId
                  axios({
                    method: "get",
                    url: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/frontLogin/getLoginUserInfo',
                    headers: {
                      "Authorization": tokenData
                    },
                  }).then(res => {
                    if (res.data.code == 200 || res.data.code == 10000) {
                      let sysUserId = res.data.data.userId
                      sessionStorage.setItem('sysUserId', JSON.stringify(sysUserId));
                    } else {
                      this.$message.warning("请求失败")
                    }
                  }).catch(err => {
                    this.$message.error('请求失败')
                  })

                }).catch(() => {
                  this.$message.error('请求异常')
                });

              } else {
                this.$message.error(res.data.msg)
              }
            }).catch(err => {
              this.$message.error('请求异常')
            })

          }
        })
      } else {
        this.activeIndex = index;
      }
    },
    uploadMethod(params) {
      const self = this;
      const file = params.file;
      const formData = new FormData();
      formData.append('file', file);
      axios({
        method: "post",
        url:  process.env.VUE_APP_HUSSAR_DEFAULT_API + "/attachment/uploadfilewithdrag",
        data: formData
      }).then(res => {
        self.uploadSuccess(res)
      }).catch(err => {
        this.$message.error('图片上传失败')
      })
    },
    uploadSuccess(res, file) {
      const self = this;
      if ((res.data.code === 10000 || res.data.code === 200) && res.data) {
        self.secondForm.orgImg = res.data.data.id;
        //self.imgUpload_112AuthSrcData = process.env.VUE_APP_HUSSAR_DEFAULT_API + `/attachment/showPicture?image=${res.data.data.id}`;

        let params = {
          fileId: res.data.data.id,
        }
        axios({
          method: "get",
          url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/file/show",
          params: params
        }).then(res => {
          if (res.data.code === 10000 || res.data.code === 200) {
            self.imgUpload_112AuthSrcData = res.data.data;

          } else {
            this.$message.warning("请求失败")
          }
        }).catch(err => {
          this.$message.error('请求失败')
        })


        self.imgUpload_112FileName = res.data.data.fileName;
        self.$message.success('上传成功');
      } else {
        self.$message.error('上传失败');
      }
    },
    //企业认证注册完成
    saveData() {
      this.$refs['secondForm'].validate((valid) => {
        if (valid) {
          let params = {
            userId: this.userId, //点击下一步返回的userId
            entityName: this.secondForm.entityName, //单位名称
            entityCode: this.secondForm.entityCode, //社会统一信用代码
            entityAddr: this.secondForm.entityAddr, //地址
            entityLegal: this.secondForm.entityLegal, //法定代表人
            entityTel: this.secondForm.entityTel, //联系电话
            businessScope: this.secondForm.businessScope, //经营范围
            orgImg: this.secondForm.orgImg//营业执照
          }
          let sysUserId = JSON.parse(sessionStorage.getItem("sysUserId"))
          params.sysUserId = sysUserId
          axios({
            method: "post",
            url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/user/updateReg",
            data: params,
          }).then(res => {
            if (res.data.code === 10000 || res.data.code === 200) {
              this.$message.success("注册成功")
              this.$emit('update:dialogVisible', false)
              //this.dialogVisible = false
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(err => {
            this.$message.error('请求异常')
          })
        }
      })

    },

    //暂不认证
    clickEnd() {
      this.$emit('update:dialogVisible', false)
      //this.dialogVisible = false
    },
    //立即登录
    loginNow() {
      this.menuIndex = 0
    },

    //获取验证码
    getCode() {
      //没有输入手机号就点击验证码按钮
      if (this.firstForm.userAccount == "") {
        this.$message.warning("请输入手机号")
      } else { //调用获取验证码按钮

        //倒计时
        let that = this
        let s = 60  //倒计时间
        if (!that.timer) {
          that.second = s
          that.disabled = true
          that.timer = setInterval(() => {
            if (that.second > 0 && this.second <= s) {
              that.second--
            } else {
              that.disabled = false
              clearInterval(that.timer)
              this.timer = null
            }
          }, 1000)
        }


        let params = {
          phoneNumber: that.firstForm.userAccount,
        }
        axios({
          method: "post",
          url: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/api/portal/user/sendSms?phoneNumber=' + that.firstForm.userAccount,
          data: params
        }).then(res => {
          if (res.data.code == 200 || res.data.code == 10000) {

          } else {
            that.$message.warning(res.data.msg)
          }
        }).catch(err => {
          that.$message.error('请求失败')
        })
      }
    },

    getBaseData(){
      this.$store.dispatch('getBaseDataEncryption');
    },
    handleLogin() {
      let self = this;
      //必填校验
      this.$refs['loginForm'].validate((valid) => {
        if (valid) {
          const passwordEncryption = new HussarPasswordEncryption();
          const userInfo = {
            username: this.loginForm.userName,
            password: passwordEncryption.encrypt(this.loginForm.userPassword), // pwd
            //password: this.loginForm.userPassword, // pwd
            code: this.loginForm.code,
            randomStr: 'blockPuzzle',
            kaptcha: this.loginForm.kaptcha,
            totp: this.loginForm.totp,
            kaptchasuffix: this.loginForm.kaptchasuffix,
            isIndex: 'index'
          };
          this.$store.dispatch('Login', { userInfo }).then(() => {
            //this.dialogVisible = false
            this.$emit('update:dialogVisible', false)
            this.visible = false
            this.$message.success("登录成功")
            //告诉在线业务部分跳转到对应的后台页面
            eventBus.$emit('toNewPath');

            let tokenDataTemp = sessionStorage.getItem("HussarToken")
            let tokenData = JSON.parse(tokenDataTemp)
            //登录成功后获取用户信息，下面的写法不知道如何传token，所以改成了下面直接调用接口
            /*this.$store.dispatch('LoginUserInfo', userInfo ).then(() => {
              console.log("获取信息接口调用成功")
            }).catch(() => {
            });*/

            //调用获取用户信息接口
            axios({
              method: "get",
              url: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/frontLogin/getLoginUserInfo',
              headers: {
                "Authorization": tokenData
              },
              params: userInfo
            }).then(res => {
              if (res.data.code == 200 || res.data.code == 10000) {
                const response = res.data
                self.$store.dispatch('SetLoginUserInfo',response).then(()=>{
                  //console.log("11111")
                });// 获取基本信息
                let phoneNum = res.data.data.userName
                sessionStorage.setItem('userName', JSON.stringify(phoneNum));
                //向父组件传值
                let isLogin = true
                this.$emit('isLogin', isLogin);
              } else {
                this.$message.warning("请求失败")
              }
            }).catch(err => {
              this.$message.error('请求失败')
            })
          }).catch(() => {
            this.$message.error('手机号或密码错误')
          });
        }
      })
    },
    registerClick() {
      this.menuIndex = 1
      this.activeIndex = 0
    }
  }
}
</script>

<style lang="less" scoped>
  .dialogClass {
    display: flex;
    height: 612px;
    .dialogLeft {
      width: 329px;
      background-image: url("../../assets/img/login/bj.png");
      //background: linear-gradient(180deg, rgba(38,130,255,0) 0%, #1C74FF 100%);
      border-radius: 12px 0px 0px 12px;
      .bottomImg {
        margin-top: 56px;
      }
      .topImg {
        position: relative;
        top: -148px;
        left: 65px;
      }
      .leftTitle {
        position: relative;
        left: 35px;
        top: -100px;
        font-size: 26px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 39px;
      }
    }
    .dialogRight {
      width: 451px;
      .tabClass {
        margin-left: 78px;
        display: flex;
        .active {
          width: 160px;
          height: 40px;
          background-image: url("~@/assets/img/login/active.png");
          border-radius: 5px;
          margin: 0 -30px 10px 0;
          z-index: 0;
          .fontClass {
            font-size: 18px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 27px;
            margin-left: 65px;
            margin-top: 5px;
          }
        }
        .unActive {
          width: 160px;
          height: 40px;
          background-image: url("~@/assets/img/login/unActive.png");
          border-radius: 5px;
          margin: 0 -30px 10px 0;
          .fontClass {
            font-size: 18px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #2C4478;
            line-height: 27px;
            margin-left: 65px;
            margin-top: 5px;
          }
        }
      }
      .tabClass:hover {
        cursor: pointer;
      }
      //登录
      .loginDiv {
        .welcome {
          margin: 50px auto 50px;
          color: #8a8a8a;
          font-size: 30px;
          font-weight: 400;
          text-align: center;
        }
      }
      //注册
      .register {
        .enterpriseDiv {
          height: 415px;
          overflow-y: auto;
        }
        .activeReg {
          margin: 5px;
          .tabSpan {
            text-align: left;
            font-size: 14px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #186CF5;
            line-height: 21px;
          }
        }
        .unActiveReg {
          margin: 5px;
          .tabSpan {
            text-align: left;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #666666;
            line-height: 21px;
          }
        }
        .codeBtn {
          color: #186CF5 ;
          background: #EEF4FF;
          border-radius: 5px;
          border: 1px solid #186CF5;
          width: 90px;
          margin-left: 8px;
        }
        .toLoginClass {
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #AAAAAA;
          line-height: 21px;
          text-align: center;
          .loginNowClass {
            color: #186CF5 ;
          }
          .loginNowClass:hover {
            cursor: pointer;
          }
        }
      }
      .moreBtn {
        width: 375px;
        height: 42px;
        background: linear-gradient(90deg, #22C5FF 0%, #186CF5 100%);
        border-radius: 5px;
        color: #FFFFFF;
      }
      .noAuthentication {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FF7900;
        line-height: 21px;
        text-align: center;
      }
      .noAuthentication:hover {
        cursor: pointer;
      }
    }
  }

  .phoneClass {
    display: flex;
  }

  ::v-deep .el-dialog {
    border-radius: 12px;
    margin-top: 10vh !important;
  }
  ::v-deep .el-dialog__header {
    padding: 0px;
  }
  ::v-deep .el-dialog__body {
    padding: 0px;
  }
  ::v-deep .el-dialog__headerbtn {
    top: 0px;
    right: -35px;
    font-size: 28px;
    border-radius: 50%;
    color: #ffffff;
  }
  ::v-deep .el-form-item {
    margin-bottom: 20px;
  }
  ::v-deep .el-input__inner {
    height: 38px;
  }
  ::v-deep .el-form-item__label {
    line-height: 22px;
  }
  ::v-deep .el-upload--picture-card {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
  ::v-deep.el-button {
    padding: 12px 9px;
  }
  ::v-deep.el-form-item__content {
    width: 356px;
  }

  ::v-deep .el-form-item__label { //form表单的
    text-align: left;
    float: none;
    word-break: break-word;
  }
</style>
