import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import MetaInfo from "vue-meta-info";
import 'element-ui/lib/theme-chalk/index.css'
import '@/utils/environmentSetup';
import store from './store'
// 引入 axios
import axios from 'axios'

import * as echarts from 'echarts'
import 'echarts-gl'

Vue.prototype.$echarts = echarts;

// 挂载一个自定义属性$http
Vue.prototype.axios = axios
Vue.use(ElementUI)
Vue.use(MetaInfo);
Vue.config.productionTip = false

new Vue({
  router,
  store: store,
  render: h => h(App)
}).$mount('#app')
