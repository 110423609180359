<template>
  <div>
    <!--导航标题-->
    <top-common></top-common>
    <router-view class="router"></router-view>
    <!--底部-->
    <fotter-common></fotter-common>
  </div>
</template>

<script>
  import topCommon from '@/components/pageTop.vue'
  import fotterCommon from '@/components/pageFooter.vue';
  export default {
    name: 'Home',
    components: { topCommon,fotterCommon },
    metaInfo: {
      title: '地理标志展示推广中心', // set a title
    },
    data() {
      return {
      }
    },
    mounted(){
    },
    methods: {
    }
  }
</script>

<style lang="less" scoped>

</style>
