<template>
    <div>
      <top-blue></top-blue>
      <router-view class="router" :key="$route.fullPath"></router-view>
      <fotter-common></fotter-common>

      <!--客服-->
      <customer-service></customer-service>

    </div>
</template>

<script>
  import topBlue from '@/components/pageTopBlue.vue'
  import fotterCommon from '@/components/pageFooter.vue'
  import customerService from '@/components/indexComponents/customerService.vue'

    export default {
      name: "blueHome",
      components: { topBlue,fotterCommon,customerService },
      data() {
        return {
        }
      },
      watch: {
        $route() {
          // console.log(this.$route)
        },
      },
      mounted(){
      },
      methods: {
      }
    }
</script>

<style scoped lang="less">
.router{
  margin-top: 80px;
  width: 100%;
}
::v-deep .w-e-text-container img {
  max-width: 100% !important;
}
</style>
